import tw from "twin.macro"
import { Button } from "../../atoms/Button"
import { SummaryViewProps } from "./ContactDealer.d"
import FormPage from "./FormPage"
import React, { useContext, useRef, useState, useEffect } from "react"
import { ContactContext } from "../../../contexts/Contact"
import {
  clearState,
  setActiveView,
  updateField,
} from "../../../contexts/Contact/actions"
import ReCAPTCHA from "react-google-recaptcha"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { tealiumNavClick, tealiumSubmit } from "./TealiumHelpers"
import { useTealiumContext } from "../../../contexts/Tealium"
import TextareaField from "./TextareaField"

const SummaryView: React.FC<SummaryViewProps> = ({
  active,
  submitting,
  sentSuccess,
  setSentSuccess,
  setModalIsOpen,
  reset,
  onSubmit,
  showFormHeading,
}) => {
  if (!active) return null

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { contactDealerData } = useTealiumContext()
  const [
    {
      dealer,
      seriesName,
      trim,
      gRecaptchaResponse,
      contactType,
      leadType,
      firstName,
      lastName,
      vehicle,
      contact,
      comments,
    },
    dispatch,
  ] = useContext(ContactContext)
  // contact info is cleared on submit but is needed for tealium event on thank you page
  const [contactPostSubmit, setContactPostSubmit] = useState(contact)
  const { _ } = useContext(LanguageContext)
  const recaptchaRef = useRef(null)
  const interestedInTranslation = _("I'm interested in the")
  const vinTranslation = _("VIN")

  useEffect(() => {
    if (active && (!comments || comments === " ") && vehicle) {
      const capitalModelName = vehicle.title?.toUpperCase()
      dispatch(
        updateField(
          "comments",
          `${interestedInTranslation} ${seriesName} ${capitalModelName?.replace(
            seriesName.toUpperCase(),
            "",
          )}, ${vinTranslation} #${vehicle.vin}.`,
        ),
      )
    }
    if (
      active &&
      (!comments || comments === " ") &&
      leadType === "Schedule Service Appointment"
    ) {
      dispatch(
        updateField(
          "comments",
          `I would like to schedule a service appointment for my vehicle.`,
        ),
      )
    }
  }, [active])

  const [isValid, setIsValid] = useState(false)
  const [showValidatioMessage, setShowValidationMessage] = useState(false)
  let leadTypeLabel: any = leadType
  if (leadType === "Schedule Service Appointment") {
    leadTypeLabel = _("Scheduling a Service Appointment")
  }

  const updateRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current?.getValue()
    dispatch(updateField("gRecaptchaResponse", recaptchaValue))
    setIsValid(true)
    setShowValidationMessage(false)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    tealiumSubmit(
      "contact_dealer_submission",
      "Review & Submit Your Request",
      contact,
      trackTealEvent,
      contactDealerData,
    )
    if (!gRecaptchaResponse) {
      setShowValidationMessage(true)
      setIsValid(false)
    } else {
      setIsValid(true)
      setContactPostSubmit(contact)
      onSubmit()
    }
  }

  // Helper function to remove duplicated series name in trim drescription
  const removeDuplicatedSeriesName = (seriesName: string, trim: string) => {
    return trim.replace(seriesName, "").trim()
  }

  const seriesIsNotSure = seriesName === _("I'm not sure")

  useEffect(() => {
    if (sentSuccess != null) {
      if (active && (!comments || comments === " ") && vehicle) {
        const capitalModelName = vehicle.title?.toUpperCase()
        dispatch(
          updateField(
            "comments",
            `${interestedInTranslation} ${seriesName} ${capitalModelName?.replace(
              seriesName.toUpperCase(),
              "",
            )}, ${vinTranslation} #${vehicle.vin}.`,
          ),
        )
      }
      if (
        active &&
        (!comments || comments === " ") &&
        leadType === "Schedule Service Appointment"
      ) {
        dispatch(
          updateField(
            "comments",
            `I would like to schedule a service appointment for my vehicle.`,
          ),
        )
      }
    }
  }, [active, sentSuccess])

  if (sentSuccess != null) {
    return (
      <div css={[tw`flex items-center justify-center mx-8 flex-col mt-20`]}>
        <article css={[tw`text-center normal-case text-lg max-w-md mb-10`]}>
          {sentSuccess === true
            ? `${_("Thank you for reaching out, a representative from")} ${
                dealer.Name
              } 
            ${_(
              "will contact you via your preferred contact method within 24 hours.",
            )}`
            : _(`An unexpected error has occurred. Please try again.`)}
        </article>
        <Button
          primary
          onClick={() => {
            reset()
            setSentSuccess(null)
            dispatch(clearState())
            setModalIsOpen(false)
            tealiumNavClick(
              "Review & Submit Your Request",
              "Close",
              trackTealEvent,
              contactDealerData,
            )
          }}
          analytics-id="Close:contact form:Review & Submit Your Request"
        >
          {_("Close")}
        </Button>
      </div>
    )
  }
  return (
    <FormPage
      active={active}
      showFormHeading={showFormHeading}
      nextButtonType={isValid ? "submit" : "button"}
      prevOnClick={() => {
        dispatch(setActiveView("NameView"))
        tealiumNavClick(
          "Review & Submit Your Request",
          "Back",
          trackTealEvent,
          contactDealerData,
        )
      }}
      nextOnClick={e => handleSubmit(e)}
      submitting={submitting}
      heading={_("Review & Submit Your Request")}
      nextButtonText={_("Submit")}
      css={tw`px-4 pt-40 lg:pt-0`}
    >
      <p
        css={[
          tw`w-full`,
          tw`lg:(mt-3 text-sm px-8 normal-case text-center break-words)`,
        ]}
      >
        <div
          css={[
            tw`text-left grid grid-cols-1 gap-4 normal-case`,
            tw`lg:(grid-cols-3 gap-4 text-left)`,
          ]}
        >
          <div>
            <span css={[tw`text-sm block`]}>{_("Contact About")}</span>
            <span css={[tw`font-semibold block text-base`]}>
              {leadTypeLabel}
              <button
                css={tw`text-red-400 text-xs py-1.5 z-10 relative block`}
                onClick={e => {
                  dispatch(setActiveView("IntroView"))
                  trackTealEvent({
                    dropdown_selected: "Change:topic",
                    tealium_event: "contact_form_nav",
                    contact_question: "Review & Submit Your Request",
                  })
                }}
              >
                {_("Change")}
              </button>
            </span>
          </div>
          <div>
            <span css={[tw`text-sm block`]}>{_("Contact Info")}</span>
            <span css={[tw`font-semibold block text-base normal-case`]}>
              {firstName} {lastName}
            </span>
            <span css={[tw`font-semibold block text-base`]}>{contact}</span>
            <button
              css={tw`text-red-400 text-xs py-1.5 z-10 relative block`}
              onClick={e => {
                dispatch(setActiveView("NameView"))
                trackTealEvent({
                  dropdown_selected: "Change:contact info",
                  tealium_event: "contact_form_nav",
                  contact_question: "Review & Submit Your Request",
                })
              }}
            >
              {_("Change")}
            </button>
          </div>
          <div css={[tw`text-sm`]}>
            {_("Comments")}
            <TextareaField
              active={active}
              fieldName="Comments"
              contactDealerSummaryView="true"
              type="text"
              label={_("Your optional comments.")}
              css={[tw`h-20 text-sm pt-1.5 !text-blue`]}
              value={comments}
              customOnChange={(value: string) => {
                dispatch(updateField("comments", value))
              }}
            />
          </div>
        </div>

        {/* {_("Contact About")} {leadTypeLabel}
        {!seriesIsNotSure &&
          seriesName &&
          trim &&
          ` for a ${seriesName} ${removeDuplicatedSeriesName(
            seriesName,
            trim
          )}`}
        {!seriesIsNotSure && seriesName && !trim && ` for a ${seriesName}`}
        {". "}
        {_("You told us your name is")} {firstName} {lastName}
        {", "}
        {_("and you want to be contacted via")} {contactType} {_("at")}{" "}
        {contact} */}
      </p>
      <div
        css={tw`mt-6 w-full max-w-[30rem] relative text-center md:mt-8 mb-2`}
      >
        <ReCAPTCHA
          sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
          ref={recaptchaRef}
          onChange={updateRecaptcha}
          css={tw`inline-block`}
        />
        {/* reCaptcha require validation popover */}
        <section
          css={[
            tw`absolute -bottom-12 left-1/2 -translate-x-1/2 overflow-hidden transition-all duration-300 w-auto`,
            showValidatioMessage ? tw`max-h-[60px]` : tw`max-h-0`,
          ]}
        >
          <div
            css={[
              tw`bg-red-400 text-gray-50 text-xs leading-3 normal-case mx-auto mt-3 py-2 px-3 rounded-md`,
              // Pointed div
              tw`before:(absolute right-[calc(50% - 10px)] top-1.5 border-solid border-[10px] border-t-0 border-transparent border-b-red-400)`,
            ]}
          >
            {_("Please confirm that you are not a robot.")}
          </div>
        </section>
      </div>
    </FormPage>
  )
}

export default SummaryView
